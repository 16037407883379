@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.carouselItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 270px;
  color: white;
  border-radius: 8px;
  font-size: 18px;
  @include medium {
    width: 300px;
    padding: 0;
    margin: 0 20px;
  }
}

.imageCard {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 340px;
  width: 100%;
  border-radius: 8px;
  @include medium {
    height: 400px;
  }
}

.burgerTitle {
  color: $color-secondary;
  font-family: $font-family-flood-std;
  font-size: 26px;
  line-height: 25px;
  text-transform: uppercase;
  letter-spacing: 0px;
  @include medium {
    font-size: 30px;
  }
}
