@import "/src/styles/variables.scss";
@import "/src/styles/breakpoints.scss";

$button-height: 28px;
$button-height-desktop: 30px;
$button-side-padding: 16px;

// Button Mixin
@mixin button(
  $bg-color: $color-primary,
  $text-color: $color-secondary,
  $padding: 0 $button-side-padding,
  $border-radius: 0.25rem,
  $font-size: 14px,
  $hover-bg-color: darken($bg-color, 10%)
) {
  height: $button-height;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $padding;
  font-family: $font-family-gibson;
  font-size: $font-size;
  font-weight: $font-weight-gibson-bold;
  line-height: normal;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 25px;
  transition-property: transform, box-shadow, color, background-color,
    border-color;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  &:disabled {
    background-color: lighten($bg-color, 15%);
    cursor: not-allowed;
    opacity: 0.6;
  }
  @include medium {
    height: $button-height-desktop;
  }
}

$elevated-button-stroke-width: 2px;
$elevated-button-offset: 4px;

// ToDo: these can be cleaned up, leaving it like this till  the numbers get
// the green light from the designer
$shadow-horizontal-offset: $elevated-button-offset;
$shadow-vertical-offset: $elevated-button-offset;

$shadow-blur-radius: 0;
$shadow-spread-radius-1: $elevated-button-stroke-width;
$shadow-spread-radius-2: $elevated-button-stroke-width;
$transform-x: $elevated-button-offset;
$transform-y: $elevated-button-offset;

@mixin elevatedButton() {
  @include button();
  box-shadow: 0 0 0 $shadow-spread-radius-1 $color-secondary,
    $shadow-horizontal-offset $shadow-vertical-offset $shadow-blur-radius
      $shadow-spread-radius-2 $color-secondary;

  transform: translate(0, 0);
  will-change: transform;
  backface-visibility: hidden;
  border: none;
  @include medium {
    font-size: 14px;
    letter-spacing: 0.28px;
    &:not([disabled]):not(.-no-hover):hover {
      transform: translate($transform-x, $transform-y);
      box-shadow: 0 0 0 $shadow-spread-radius-1 $color-secondary,
        0 0 0 $shadow-spread-radius-2 $color-secondary;
    }
  }
}

/* Base Button */
.button {
  @include button();
}

/* Button Types */
.text {
  @include button();
  background: none;
  text-decoration: underline;
}
.normal {
  @include button();
  border: 2px solid $color-secondary;
  border-radius: calc($button-height / 2);
  &:not([disabled]):not(.-no-hover):hover {
    transform: translate(3px, 3px);
  }
}
.elevated {
  @include elevatedButton();
  &.selected {
    background: transparent;
    transform: translate($transform-x, $transform-y);
    box-shadow: 0 0 0 $shadow-spread-radius-1 $color-secondary,
      0 0 0 $shadow-spread-radius-2 $color-secondary;
  }
}

.optionButton {
  @include elevatedButton();
  &:hover .circleThing::after {
    background-color: $color-secondary;
  }
}

.circleThing {
  position: relative;
  height: 12px;
  width: 12px;
  border: 1px solid $color-secondary;
  border-radius: 50%;
  margin-left: 8px;
  cursor: pointer;

  &::after {
    height: 6px;
    width: 6px;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border-radius: 50%;
  }

  &.checked::after {
    background-color: $color-secondary;
  }
}
