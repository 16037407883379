@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.parallax {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $color-neutral-1;
}

.cardParallaxContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(0);
  transition: transform 0.1s linear;
  padding: 0 10px;
}

.background {
  position: absolute;
  background-color: $color-black;
  top: 0;
  z-index: 2;
  padding: 20px;
  text-align: center;
  width: 100%;
  height: 33vh;
  @include medium {
    max-height: 300px;
    flex-direction: row;
  }
}

.card {
  display: flex;
  position: relative;
  flex-direction: column;
  box-shadow: 0px 0px 30px #0000004d;
  border: 2px solid $color-white;
  border-radius: $radius-lg;
  z-index: 2;
  background-color: $color-white;
  max-width: 800px;

  @include medium {
    flex-direction: row;
  }
  h2 {
    color: $color-secondary;
    text-shadow: none;
  }
  .imageContainer {
    position: relative;
    flex: 1;
    order: 1;
    background-color: $color-primary;
    border-top-left-radius: $radius-lg;
    border-top-right-radius: $radius-lg;
    z-index: 10;
    @include medium {
      min-height: 413px;
      order: 2;
      border-top-left-radius: 0;
      border-top-right-radius: $radius-lg;
      border-bottom-right-radius: $radius-lg;
    }
    div {
      min-height: 250px;
      height: 50vw;
      border-radius: inherit;

      @include medium {
        min-height: unset;
        height: 100%;
      }
    }
  }

  .image {
    border-top-left-radius: $radius-lg;
    border-top-right-radius: $radius-lg;
    @include medium {
      border-top-left-radius: 0;
      border-top-right-radius: $radius-lg;
      border-bottom-right-radius: $radius-lg;
    }
  }

  .contentContainer {
    flex: 1;
    order: 2;
    background-color: $color-primary;
    border-bottom-left-radius: $radius-lg;
    border-bottom-right-radius: $radius-lg;
    @include medium {
      order: 1;
      border-top-left-radius: $radius-lg;
      border-bottom-left-radius: $radius-lg;
      border-bottom-right-radius: 0;
    }
    .contentInner {
      padding: 30px 30px 40px 30px;
      min-height: 100%;
      @include medium {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        order: 1;
        padding: 50px;
      }
      h2 {
        margin-top: 0;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 20px;
      }
    }
  }

  .actionButton {
    &:hover {
      color: $color-white;
      border-color: $color-white;
    }
  }
}
