@import "/src/styles/variables.scss";
@import "/src/styles/breakpoints.scss";
@import "/src/components/button/Button.module.scss";

.form {
  margin-top: 24px;
  margin-bottom: 60px;
}

.gridSection {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
  @include medium {
    gap: 30px;
    grid-template-columns: 1fr 1fr;
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  @include medium {
    flex-wrap: nowrap;
  }
}

.submitWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @include medium {
    width: unset;
  }
}

.recaptchaTerms {
  margin: 40px 0 0 0;
  width: 100%;
  text-align: center;
  @include medium {
    margin: 0 0 0 40px;
    text-align: left;
  }
}
