@import "/src/styles/variables.scss";
@import "/src/styles/breakpoints.scss";

.controlsMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 40px 0;
  @include medium {
    display: none;
  }
}

// .controlsDesktop {
//   display: none;
//   @include medium {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     margin: 40px 0;
//   }

//   .optionButton {
//     padding: 0 12px;
//   }
// }

.controlsDesktop {
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
  margin: 40px 0;
  @include medium {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0;
  }

  .optionButton {
    padding: 0 12px;
  }
}

.enquiryFormIntro {
  display: none;
  @include medium {
    display: block;
  }
}

.enter {
  opacity: 0;
  transform: translateY(24px);
}

.enterActive {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.exit {
  opacity: 1;
  transform: translateY(0);
}

.exitActive {
  opacity: 0;

  transform: translate(0px, 50px);

  transition: opacity 0ms, transform 300ms;
}
