@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.parallax {
  background-color: $color-neutral-1;
  padding-bottom: 110px;
  @include medium {
    padding-bottom: 0;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $color-neutral-1;
    max-width: 800px;
    height: 100vh;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}

.contentParallaxContainer {
  @include medium {
    position: relative;
    order: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0);
    transition: transform 0.1s linear;
    padding: 0 10px;
  }
}

.imageParallaxContainer {
  position: relative;
  top: 0;
  z-index: 2;
  text-align: center;
  width: 100%;
  aspect-ratio: 9 / 11;
  order: 2;
  @include medium {
    position: relative;
    top: 0;
    z-index: 2;
    padding: 0 20px;
    text-align: center;
    width: 100%;
    height: 60%;
    order: 2;
  }
}

.section {
  .sectionInner {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 100px;
    @include medium {
      flex-direction: row-reverse;
    }
  }

  .imageContainer {
    position: relative;
    width: 100%;
    // padding-top: 67.57%; /* Aspect ratio for 370x250 */
    // padding-top: 101.36%; // designer asked for extra 50% from 67.57%, just round to 100%
    padding-top: 100%; /* Aspect ratio of 1 */
    object-position: top;
    @include medium {
      padding-top: 70%; /* Aspect ratio for 400x560 half of(140%) for flex basis */
      flex-basis: 50%;
      object-position: center;
    }
  }

  .image {
    object-position: top;
    @include medium {
      object-position: center;
    }
  }

  .contentWrapper {
    @include medium {
      flex-direction: row-reverse;
      flex-basis: 50%;
    }

    p {
      margin-bottom: 40px;
    }
  }
}
