@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-family-gibson;
  margin: $spacing-4 0;
  font-weight: bold;
  line-height: 1.2;
}

.h1 {
  color: $color-primary;
  font: normal normal $font-weight-gibson-heavy 45px/47px $font-family-gibson;
  letter-spacing: 0.9px;
  text-shadow: 3px 3px 0px $color-secondary;
  text-transform: uppercase;
  margin: $spacing-6 0;
  @include medium {
    font-size: 55px;
    line-height: 55px;
    letter-spacing: 1.2px;
  }
}

.h2 {
  color: $color-primary;
  font: normal normal $font-weight-gibson-heavy 36px/37px $font-family-gibson;
  letter-spacing: 0.72px;
  text-shadow: 3px 3px 0px $color-secondary;
  text-transform: uppercase;
  margin: $spacing-6 0;
  @include medium {
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0.8px;
    text-shadow: 3px 3px 0px $color-secondary;
    text-transform: uppercase;
  }
}

.h3 {
  font-weight: 600;
}

.body1 {
  color: $text-color;
  font: normal normal $font-weight-gibson-regular 16px/22px $font-family-gibson;
  letter-spacing: 0px;
  text-align: left;
  @include medium {
    font-size: 18px;
    line-height: 24px;
  }
}

.body2 {
  color: $text-color;
  font: normal normal $font-weight-gibson-regular 12px/16px $font-family-gibson;
  letter-spacing: 0px;
  text-align: left;
}

.subheading {
  font: normal normal $font-weight-gibson-heavy 16px/16px $font-family-gibson;
  color: $color-secondary;
}

.caption {
  font-size: 10px;
  line-height: 14px;
}

.gutterBottom {
  margin-bottom: 24px;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}

.disableMargin {
  margin: 0;
}
