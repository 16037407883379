@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.imageSlider {
  position: absolute;
  width: calc(80% - (($side-padding) * 2));
  // height: 530px; // height was 292px added an extra 50% for 530px as suggested by designer
  // min-height: 292px;
  height: 60%;
  z-index: 1;
  left: 0;
  @include medium {
    height: 530px;
    max-width: 500px;
    padding: 0;
  }
}

.image {
  z-index: 1;
}

.content {
  position: absolute;
  width: 80%;
  margin-left: auto;
  z-index: 2;
  top: 50%;
  right: 10px;
  h2 {
    margin-top: 0;
    text-align: left;
  }
  p {
    color: $color-white;
  }
  @include medium {
    padding: 0;
    width: 70%;
  }
}
