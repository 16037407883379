@import "/src/styles/variables.scss";

.heading {
  margin-top: var(--appbar-height);
  text-align: center;
  margin-bottom: 36px;
  max-width: $body-text-max-width;
}

.body {
  text-align: center;
  max-width: $body-text-max-width;
}
